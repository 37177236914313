import { render, staticRenderFns } from "./About.vue?vue&type=template&id=97302e10&"
var script = {}
import style0 from "./About.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/home/ernesto/tera/Dropbox/programas/2019/chess-club-1/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
installComponents(component, {VBadge,VContainer,VFlex,VLayout,VSpacer})
